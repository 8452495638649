<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="产品名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="产品车型" prop="model_id">
        <el-select v-model="info.model_id" placeholder="请选择" @change="changeModel">
          <el-option
              v-for="item in carModelList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类目" prop="menu_id">
        <el-select v-model="info.menu_id" placeholder="请选择">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商">
        <el-input v-model="info.supplier" placeholder="请输入供应商"></el-input>
      </el-form-item>
      <el-form-item label="成本价" prop="cost_price">
        <el-input v-model="info.cost_price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="销售价格" prop="price">
        <el-input v-model="info.price" placeholder="请输入价格">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="工时费用" prop="work_price">
        <el-input v-model="info.work_price" placeholder="请输入费用">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="工时">
        <el-input v-model="info.work_num" placeholder="请输入工时"></el-input>
      </el-form-item>
      <el-form-item label="月度目标数">
        <el-input v-model="info.month_play_num" placeholder="请输入数量">
          <template slot="append">个</template>
        </el-input>
      </el-form-item>
      <el-form-item label="技术提成">
        <el-input v-model="info.technical_commission" placeholder="请输入技术提成">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="扩展字段">
        <div class="it-box">
          <div class="item mg-bt-5" v-for="(item,index) in fieldList" :key="index">
            <el-row>
              <el-col :span="12" class="mg-rt-10">
                <el-input v-model="item.value" placeholder="请输入"></el-input>
              </el-col>
              <el-col :span="10" style="color: #666">
                {{ item.title }}
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="closePage()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      carModelList: [],
      tableHeader: [],
      tableData: [],
      menuList: [],
      fieldList: [],
      info: {
        id: 0,
        model_id: '',
        menu_id: '',
        supplier: '',
        work_price: '',
        work_num: '',
        month_play_num: '',
        technical_commission: '',
      },
      source_domain: this.config.SOURCE_DOMAIN,
      carModelInfo: false,
      rules: {
        title: [
          {required: true, message: '请输入商品名称', trigger: 'blur'}
        ],
        model_id: [
          {required: true, message: '请选择车型', trigger: 'blur'}
        ],
        // menu_id: [
        //   {required: true, message: '请选择分类', trigger: 'blur'}
        // ],
        cost_price: [
          {required: true, message: '请输入成本价', trigger: 'blur'}
        ],
        price: [
          {required: true, message: '请输入销售价格', trigger: 'blur'}
        ]
      },
      showInput: false,
      shop_id: 0
    };
  },
  created() {
    // this.getMenuList()
    if (this.$route.query.model_id > 0) {
      console.log('ssssssss')
      this.info.model_id = parseInt(this.$route.query.model_id)
      this.getCarModelInfo()
    }
    if(this.$route.query.store_id) {
      this.shop_id = this.$route.query.store_id
    }
    this.getCarModelList()
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
  },
  computed: {},
  methods: {
    changeModel(e) {
      this.getCarModelInfo()
    },
    updateField() {
      if(this.fieldList && this.fieldList.length>0 && this.info.id>0 && this.info.fields) {
        var fieldList = []
        this.fieldList.forEach(item => {
          fieldList.push({
            key: item.key,
            title: item.title,
            value: this.info.fields[item.key] ? this.info.fields[item.key].value : item.value
          })
        })
        this.fieldList = fieldList
      }
    },
    getMenuList() {
      this.$api.operate.goodsMenuIndex({page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data.data
        }
      })
    },
    getCarModelInfo() {
      this.$api.operate.carModelDetail({id: this.info.model_id}, res => {
        if (res.errcode == 0) {
          this.carModelInfo = res.data
          if(this.carModelInfo.fields || this.carModelInfo.fields.length>0) {
            var fieldList = []
            this.carModelInfo.fields.forEach(item => {
              fieldList.push({
                key: item.key,
                title: item.title,
                value: ''
              })
            })
            this.fieldList = fieldList
            this.menuList = res.data.menuList
            this.info.menu_id = ''
            this.updateField()
          }
        }
      })
    },
    getCarModelList() {
      this.$api.operate.carModelIndex({store:this.shop_id,page_size: 1000}, res => {
        if (res.errcode == 0) {
          this.carModelList = res.data.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.operate.goodsDetail({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
          that.updateField()
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = this.info
          param.fields = this.fieldList
          if(this.shop_id>0) {
            param.store_id = this.shop_id
          }
          if (this.info.id == 0) {
            this.$api.operate.goodsAdd(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.closePage()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.operate.goodsAdd(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.closePage()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    closePage() {
      setTimeout(function () {
        window.close()
      },500)
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
